<template>
    <div id="index">
        <dv-full-screen-container class="bg">
            <dv-loading v-if="loading">加载中...</dv-loading>
            <div class="host-body">
                <!-- 第一行 头部 -->
                <div class="d-flex jc-center">
                    <dv-decoration-3 style="width:33.3%;height:0.45rem;"/>
                    <div class="d-flex jc-center">
                        <dv-decoration-8 :color="['#0d77ea', '#000000']" style="width:2.5rem;height:.625rem;"/>
                        <div class="title">
                            <span class="title-text"><router-link to="/">千河物流园智慧大屏</router-link></span>
                            <dv-decoration-6 :color="['#50e3c2', '#0d77ea']" :reverse="true" class="title-bototm" style="width:3.125rem;height:.1rem;"/>
                        </div>
                        <div class="title">
                            <span class="title-text"><router-link to="/index2">千河物流园智慧大屏</router-link></span>
                            <dv-decoration-6 :color="['#50e3c2', '#0d77ea']" :reverse="true" class="title-bototm" style="width:3.125rem;height:.1rem;"/>
                        </div>
                        <div class="title">
                            <span class="title-text"><router-link to="/index4">绥德物流园智慧大屏</router-link></span>
                            <dv-decoration-6 :color="['#50e3c2', '#0d77ea']" :reverse="true" class="title-bototm" style="width:3.125rem;height:.1rem;"/>
                        </div>
                        <dv-decoration-8 :color="['#0d77ea', '#000000']" :reverse="true" style="width:2.5rem;height:.625rem;"/>
                    </div>
                    <dv-decoration-3 style="width:33.3%;height:0.45rem; transform: rotateY(180deg);"/>
                </div>
                <!-- 第二行 -->
                <div class="d-flex jc-between px-2">
                    <div class="d-flex" style="width: 40%">
                        <div class="react-right ml-4"
                             style="width: 6.25rem; text-align: left;background-color: #0f1325;">
                            <span class="react-before"></span>
                            <span class="text">数据分析</span>
                        </div>
                    </div>
                    <div class="d-flex" style="width: 40%">
                        <div class="react-left mr-3" style="background-color: #0f1325;">
                            <span class="text fw-b">{{dateYear}} {{dateWeek}} {{dateDay}}</span>
                        </div>
                        <div class="react-left mr-4"
                             style="width: 6.25rem; background-color: #0f1325; text-align: right;">
                            <span class="react-after"></span>
                            <span class="text"><dv-decoration-1 style="width:200px;height:50px;"/></span>
                        </div>
                    </div>
                </div>
                <div class="bottom-box">
                    <div class="body-box" style="height: 33.333%">
                        <!-- 第三行数据 -->
                        <dv-border-box-12 style="padding: 15px">
                            <table border="1" style="width: 100%;text-align: center;">
                                <caption style="text-align: center;color: #fff;">采购数据</caption>
                                <tr>
                                    <th style="text-align: center;" colspan="2">派车数量</th>
                                    <th style="text-align: center;" colspan="3">运输中</th>
                                    <th style="text-align: center;" colspan="3">排队中</th>
                                    <th style="text-align: center;" colspan="2">卸货中</th>
                                    <th style="text-align: center;" colspan="2">实收数</th>
                                </tr>
                                <tr>
                                    <td>车牌号码</td>
                                    <td>派车时间</td>
                                    <td>车牌号码</td>
                                    <td>矿发重量</td>
                                    <td>装车时间</td>
                                    <td>车牌号码</td>
                                    <td>签到时间</td>
                                    <td>排队序号</td>
                                    <td>车牌号码</td>
                                    <td>进场时间</td>
                                    <td>车牌号码</td>
                                    <td>实收重量</td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <dv-scroll-board :config="configCGPC" style="height:200px;width:226px;"/>
                                    </td>
                                    <td colspan="3">
                                        <dv-scroll-board :config="configCGYS" style="height:200px;width:339px;"/>
                                    </td>
                                    <td colspan="3">
                                        <dv-scroll-board :config="configCGPD" style="height:200px;width:339px;"/>
                                    </td>
                                    <td colspan="2">
                                        <dv-scroll-board :config="configCGXH" style="height:200px;width:226px;"/>
                                    </td>
                                    <td colspan="2">
                                        <dv-scroll-board :config="configCGSH" style="height:200px;width:226px;"/>
                                    </td>
                                </tr>
                            </table>

                        </dv-border-box-12>
                    </div>
                    <div class="body-box" style="height: 33.333%">
                        <!-- 中间 -->
                        <dv-border-box-12 style="padding: 15px">
                            <table border="1" style="width: 100%;text-align: center;">
                                <caption style="text-align: center;color: #fff;">销售数据</caption>
                                <tr>
                                    <th style="text-align: center;" colspan="2">派车数量</th>
                                    <th style="text-align: center;" colspan="3">排队中</th>
                                    <th style="text-align: center;" colspan="2">装货中</th>
                                    <th style="text-align: center;" colspan="2">实发数</th>
                                    <th style="text-align: center;" colspan="2">运输中</th>
                                    <th style="text-align: center;" colspan="2">实卸量</th>
                                </tr>
                                <tr>
                                    <td>车牌号码</td>
                                    <td>派车时间</td>
                                    <td>车牌号码</td>
                                    <td>签到时间</td>
                                    <td>排队序号</td>
                                    <td>车牌号码</td>
                                    <td>进场时间</td>
                                    <td>车牌号码</td>
                                    <td>实发重量</td>
                                    <td>车牌号码</td>
                                    <td>运输时长</td>
                                    <td>车牌号码</td>
                                    <td>卸货重量</td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <dv-scroll-board :config="configXSPC" style="height:200px;width: 214px"/>
                                    </td>
                                    <td colspan="3">
                                        <dv-scroll-board :config="configXSPD" style="height:200px;width: 315px"/>
                                    </td>
                                    <td colspan="2">
                                        <dv-scroll-board :config="configXSZH" style="height:200px;width: 214px"/>
                                    </td>
                                    <td colspan="2">
                                        <dv-scroll-board :config="configXSSF" style="height:200px;width: 214px"/>
                                    </td>
                                    <td colspan="2">
                                        <dv-scroll-board :config="configXSYS" style="height:200px;width: 214px"/>
                                    </td>
                                    <td colspan="2">
                                        <dv-scroll-board :config="configXSSX" style="height:200px;width: 214px"/>
                                    </td>

                                </tr>
                            </table>
                        </dv-border-box-12>
                    </div>
                    <div class="body-box" style="height: 33.333%">
                        <!-- 中间 -->
                        <dv-border-box-12 style="padding: 15px">
                            <table border="1" style="width: 100%;text-align: center;">
                                <caption style="text-align: center;color: #fff;">铁运数据</caption>
                                <tr>
                                    <td width="118px">计划号</td>
                                    <td width="118px">车次</td>
                                    <td width="114">节数</td>
                                    <td width="114">标吨</td>
                                    <td width="118px">轨道衡</td>
                                    <td width="118px">发车时间</td>
                                    <td width="118px">发站</td>
                                    <td width="118px">到站</td>
                                    <td width="118px">到站时间</td>
                                    <td width="118px">到站轨道衡</td>
                                    <td width="118px">物料</td>
                                    <td width="118px">客户</td>
                                </tr>
                                <tr>
                                    <td colspan="12">
                                        <dv-scroll-board :config="configTY" style="height:200px;width: 1392px"/>
                                    </td>
                                </tr>
                            </table>
                        </dv-border-box-12>
                    </div>
                </div>
            </div>
        </dv-full-screen-container>
    </div>
</template>

<!-- 最新的 Bootstrap 核心 JavaScript 文件 -->
<script src="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/js/bootstrap.min.js"></script>
<script>
    import {formatTime} from '../utils/index.js';

    export default {
        data() {
            return {
                userName: '',
                password: '',
                loading: true,
                dateDay: null,
                dateYear: null,
                dateWeek: null,
                weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
                configCGPC: {//采购派车
                    rowNum: 8,
                    columnWidth: [113, 113],
                    data: [],
                },
                configCGYS: {//采购运输
                    rowNum: 8,
                    columnWidth: [113, 113, 113],
                    data: [],
                },
                configCGPD: {//采购排队
                    rowNum: 8,
                    columnWidth: [113, 113, 113],
                    data: [],
                },
                configCGXH: {//采购卸货中
                    rowNum: 8,
                    columnWidth: [113, 113],
                    data: [],
                },
                configCGSH: {//采购实收
                    rowNum: 8,
                    columnWidth: [113, 113],
                    data: [],
                },
                configXSPC: {
                    columnWidth: [108.5, 108.5],
                    rowNum: 8,
                    data: [],
                    headerBGC: '#00BAFF',
                },
                configXSPD: {
                    columnWidth: [108.5, 108.5, 108.5],
                    rowNum: 8,
                    data: [],
                    headerBGC: '#00BAFF',
                },
                configXSZH: {
                    columnWidth: [108.5, 108.5],
                    rowNum: 8,
                    data: [],
                    headerBGC: '#00BAFF',
                },
                configXSSF: {
                    columnWidth: [108.5, 108.5],
                    rowNum: 8,
                    data: [],
                    headerBGC: '#00BAFF',
                },
                configXSYS: {
                    columnWidth: [108.5, 108.5],
                    rowNum: 8,
                    data: [],
                    headerBGC: '#00BAFF',
                },
                configXSSX: {
                    columnWidth: [108.5, 108.5],
                    rowNum: 8,
                    data: [],
                    headerBGC: '#00BAFF',
                },
                configTY: {
                    columnWidth: [118, 118, 114, 114, 118, 118, 118, 118, 118, 118, 118, 118],
                    rowNum: 8,
                    tydata: []
                },

            };
        },
        beforeMount() {
            this.change()
        },
        mounted() {
            this.timeFn();
            this.cancelLoading();
            this.changeTiming();
        },
        methods: {
            timeFn() {
                setInterval(() => {
                    this.dateDay = formatTime(new Date(), 'HH: mm: ss');
                    this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
                    this.dateWeek = this.weekday[new Date().getDay()];
                }, 1000)
            },
            cancelLoading() {
                setTimeout(() => {
                    this.loading = false;
                }, 2200);
            },
            changeTiming() {
                setInterval(() => {
                    this.change();
                }, 300000);
            },
            compare(property) {
                return function (a, b) {
                    var value1 = a[property];
                    var value2 = b[property];
                    return value2 - value1;
                }
            },
            change() {
                var that = this
                this.$axios.get(this.$axios.defaults.zcbaseURL).then(function (response) {
                    if (response.data.code == 0) {
                        //采购数据
                        let cgPdArr = []
                        let cgYsArr = []
                        let cgLcArr = []
                        response.data.data.cgPd.forEach((item, index) => {
                            let cgPdArrChild = [];
                            cgPdArrChild[0] = item.ch;
                            cgPdArrChild[1] = item.pdsj;
                            cgPdArrChild[2] = index+1;
                            cgPdArr.push(cgPdArrChild)
                        })
                        that.configCGPD= {//采购排队
                            rowNum: 8,
                                columnWidth: [113, 176,50],
                                data: cgPdArr
                        }
                        //采购卸货
                        response.data.data.cgYs.forEach((item,index)=>{
                            let cgYsArrChild = [];
                            cgYsArrChild[0] = item.ch
                            cgYsArrChild[1] = item.ys
                            cgYsArr.push(cgYsArrChild)
                        })
                        that.configCGXH= {//采购派车
                            rowNum: 8,
                            columnWidth: [113, 113],
                            data: cgYsArr
                        }
                        //采购实收
                        response.data.data.cgLc.forEach((item,index)=>{
                            let cgLcArrChild = [];
                            cgLcArrChild[0] = item.ch
                            cgLcArrChild[1] = item.weight
                            cgLcArr.push(cgLcArrChild)
                        })
                        that.configCGSH= {//采购派车
                            rowNum: 8,
                            columnWidth: [113, 113],
                            data: cgLcArr
                        }
                        that.cgysdata = [];//采购运输中
                        //销售数据
                        //xcPd xsYs cxLc
                        let xcPdArr = []
                        let xsYsArr = []
                        let cxLcArr = []
                        response.data.data.xcPd.forEach((item, index) => {
                            let xsPdArrChild = [];
                            xsPdArrChild[0] = item.ch;
                            xsPdArrChild[1] = item.qdsj;
                            xsPdArrChild[2] = index+1;
                            xcPdArr.push(xsPdArrChild)
                        })
                        that.configXSPD= {//采购排队
                            rowNum: 8,
                            columnWidth: [113, 176,50],
                            data: xcPdArr
                        }
                        //采购卸货
                        response.data.data.xsYs.forEach((item,index)=>{
                            let xsYsArrChild = [];
                            xsYsArrChild[0] = item.ch
                            xsYsArrChild[1] = item.ys
                            xsYsArr.push(xsYsArrChild)
                        })
                        that.configXSZH= {//
                            rowNum: 8,
                            columnWidth: [113, 113],
                            data: xsYsArr
                        }
                        //采购实收
                        response.data.data.cxLc.forEach((item,index)=>{
                            let cxLcArrChild = [];
                            cxLcArrChild[0] = item.ch
                            cxLcArrChild[1] = item.weight
                            cxLcArr.push(cxLcArrChild)
                        })
                    } else {
                        console.log("数据获取失败")
                    }
                }).catch(function (error) {
                    console.log(error);
                });
                this.$axios.get('http://localhost:8080/coal_master/dataPool/transportTlDataPool/transportTlList?hcId=6').then(function (res) {
                    if (res.data.code == 0){
                        let tyArr = []
                        res.data.data.jsonContractTy.forEach((item, index) => {
                            let tyArrChild = [];
                            tyArrChild[0] = item.planNo;
                            tyArrChild[1] = item.zcCheci;
                            tyArrChild[2] = item.zcNum;
                            tyArrChild[3] = item.zcBiaodun;
                            tyArrChild[4] = item.zcSumdun;
                            tyArrChild[5] = item.outDate;
                            tyArrChild[6] = item.zcstationName;
                            tyArrChild[7] = item.dhstationName;
                            tyArrChild[8] = item.inDate;
                            tyArrChild[9] = item.dzSumDun;
                            tyArrChild[10] = item.materielNames;
                            tyArrChild[11] = item.buyerName;
                            tyArr.push(tyArrChild)
                        })
                        that.configTY= {//采购排队
                            rowNum: 8,
                            columnWidth: [118, 118, 114, 114, 118, 118, 118, 118, 118, 118, 118, 118],
                            data: tyArr
                        }
                    }else{
                        console.log("数据获取失败")
                    }
                })
                //销售卸货
                this.$axios.get('http://localhost:8080/coal_master/dataPool/coaltransrecDataPool/coaltransrecList?hcId=6').then(function (res) {
                    if (res.data.code == 0){
                        let xsxhArr = []
                        res.data.data.returnObj.forEach((item, index) => {
                            let xsxhArrChild = [];
                            xsxhArrChild[0] = item.ch;
                            xsxhArrChild[1] = item.xh;
                            xsxhArr.push(xsxhArrChild)
                        })
                        that.configXSSX= {//销售实卸
                            rowNum: 8,
                            columnWidth: [108.5, 108.5],
                            data: xsxhArr
                        }
                    }else{
                        console.log("数据获取失败")
                    }
                })
                //采购派车
                this.$axios.post('http://localhost:8080/coal_master/dataPool/lianjieDataPool/getLianjiePCData?hcId=24&planType=0').then(function (res) {
                    if (res.data.code == 0){
                        console.log(res.data)
                        let cgpcArr = []
                        res.data.data.forEach((item, index) => {
                            let cgpcArrChild = [];
                            cgpcArrChild[0] = item.ch;
                            cgpcArrChild[1] = item.createTime;
                            cgpcArr.push(cgpcArrChild)
                        })
                        that.configCGPC= {//销售实卸
                            rowNum: 8,
                            columnWidth: [108.5, 108.5],
                            data: cgpcArr
                        }
                    }else{
                        console.log("数据获取失败")
                    }
                })
                //销售派车
                this.$axios.post('http://localhost:8080/coal_master/dataPool/lianjieDataPool/getLianjiePCData?hcId=24&planType=1').then(function (res) {
                    if (res.data.code == 0){
                        console.log("data")
                        console.log(res.data)
                        console.log("data")
                        let cgpcArr = []
                        res.data.data.forEach((item, index) => {
                            let cgpcArrChild = [];
                            console.log("item"+item)
                            cgpcArrChild[0] = item.ch;
                            cgpcArrChild[1] = item.createTime;
                            cgpcArr.push(cgpcArrChild)
                        })
                        that.configXSPC= {//销售实卸
                            rowNum: 8,
                            columnWidth: [108.5, 108.5],
                            data: cgpcArr
                        }
                    }else{
                        console.log("数据获取失败")
                    }
                })

                //采购运输中
                this.$axios.post('http://localhost:8080/coal_master/dataPool/lianjieDataPool/getTransportList?hcId=24&planType=0').then(function (res) {
                    if (res.data.code == 0){
                        console.log(res.data)
                        let cgpcArr = []
                        res.data.data.forEach((item, index) => {
                            let cgpcArrChild = [];
                            cgpcArrChild[0] = item.licensePlate;
                            cgpcArrChild[1] = item.loadingNum;
                            cgpcArrChild[2] = item.loadingTime;
                            cgpcArr.push(cgpcArrChild)
                        })
                        that.configCGYS= {//销售实卸
                            rowNum: 8,
                            columnWidth: [108.5, 108.5],
                            data: cgpcArr
                        }
                    }else{
                        console.log("数据获取失败")
                    }
                })
                //销售运输中
                this.$axios.post('http://localhost:8080/coal_master/dataPool/lianjieDataPool/getTransportList?hcId=24&planType=1').then(function (res) {
                    if (res.data.code == 0){
                        console.log("data")
                        console.log(res.data)
                        console.log("data")
                        let cgpcArr = []
                        res.data.data.forEach((item, index) => {
                            let cgpcArrChild = [];
                            console.log("item"+item)
                            cgpcArrChild[0] = item.licensePlate;
                            cgpcArrChild[1] = item.loadingTime;
                            cgpcArr.push(cgpcArrChild)
                        })
                        that.configXSYS= {//销售实卸
                            rowNum: 8,
                            columnWidth: [108.5, 108.5],
                            data: cgpcArr
                        }
                    }else{
                        console.log("数据获取失败")
                    }
                })


            },
        }
    };

    function formatter(number) {
        const numbers = number.toString().split('').reverse()
        const segs = []

        while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

        return segs.join(',').split('').reverse().join('')
    }
</script>

<style lang="scss">
    @import "https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css";
    @import '../assets/scss/index.scss';

    .dv-border-box-11-title {
        font-size: 14px;
    }

    .bottom-box {
        height: calc(100% - 800px);
    }
</style>
